<template>
  <div>
    <b-card title="Farmacia">
      <h3>Servicio: HOSPITALIZACIÓN PISO 3</h3>
      <br>
      <h4>Estadísticas del día</h4>
      <div class="container-estadisticas">
        <div class="container-for-indicative">
          <div class="header-container">
            <h5>CAMAS</h5>
            <h5>MEDICAMENTOS ENFERMERIA</h5>
          </div>
          <div class="body-container">
            <div class="container-chart">
              <vue-apex-charts
                height="120"
                :options="earningsChart.chartOptions"
                :series="earningsChart.series"
              />
            </div>
            <div class="container-indicatives">
              <div class="container-indicative">
                <feather-icon
                  class="icon-indicative"
                  icon="CheckSquareIcon"
                />
                <span class="name-indicative">Entregados</span>
                <span class="tam-indicative">
                  6
                </span>
              </div>
              <div class="container-indicative">
                <feather-icon
                  class="icon-indicative"
                  icon="SlashIcon"
                />
                <span class="name-indicative">Mo Entregados</span>
                <span class="tam-indicative">
                  0
                </span>
              </div>
              <div class="container-indicative">
                <feather-icon
                  class="icon-indicative"
                  icon="DownloadIcon"
                />
                <span class="name-indicative">Devoluciones</span>
                <span class="tam-indicative">
                  2
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-for-indicative">
          <div class="header-container">
            <h5>MEDICAMENTOS PENDIENTES</h5>
          </div>
          <div class="body-container">
            <div class="container-indicatives">
              <div class="container-indicative">
                <feather-icon
                  class="icon-indicative"
                  icon="DownloadIcon"
                />
                <span class="name-indicative">Devoluciones</span>
                <span class="tam-indicative">
                  20
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <b-table
        small
        :fields="fields"
        :items="items"
        responsive="sm"
      >
        <!-- A virtual column -->
        <template #cell(location)="data">
          {{ data.value.name }}
          <b-badge variant="dark">
            {{ data.value.temp }}
          </b-badge>
        </template>

        <!-- A custom formatted column -->
        <template #cell(risk)="data">
          {{ data.value }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(patient)="data">
          {{ data.value.first + ' ' + data.value.last }}
        </template>

        <template #cell(eps)="data">
          {{ data.value }}
        </template>

        <!-- A virtual composite column -->
        <template #cell(medicine)="data">
          <div class="container-medicine">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon btn-edit-medicine"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <div>
              <label>Administrados {{ data.value }} / 5 </label>
              <b-progress
                :max="5"
                class="progress-bar-info"
              >
                <b-progress-bar
                  :value="data.value"
                  :label="`${((data.value / 5) * 100).toFixed()}%`"
                  variant="info"
                />
              </b-progress>
            </div>
          </div>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell(cons_medicine)>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-edit-medicine"
            size="sm"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Cons medicamentos a</span>
          </b-button>
        </template>

        <template #cell(profile)>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-edit-medicine"
            size="sm"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Perfil de Fármaco</span>
          </b-button>
        </template>

        <template #cell(action)>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-edit-medicine"
            size="sm"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span class="align-middle">Ver</span>
          </b-button>
        </template>
      </b-table>
    </b-card>

  </div>
</template>

<script>
import {
 BCard, BCardText, BLink, BFormSelect, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BProgress, BBadge, BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

// Services
import { registerPatient } from '@core/services/patientServise'

// Models
import User from '@core/models/user'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BProgress,
    BProgressBar,
    BBadge,
    VueApexCharts,
  },
  data() {
    return {
      loading: false,
      selected: null,
      typeDocumentSelected: 'CC',
      genderSelected: 'M',
      birdthdaySelected: '',
      document: '',
      name: '',
      lastName: '',
      fields: [
        { key: 'location', label: 'Cama - Tem' },
        { key: 'risk', label: 'Riesgo' },
        { key: 'patient', label: 'Paciente' },
        { key: 'eps', label: 'EPS' },
        { key: 'medicine', label: 'Medicamentos' },
        { key: 'cons_medicine', label: 'Cons medicamentos a' },
        { key: 'profile', label: 'Perfil' },
        { key: 'action', label: 'Acción' },
      ],
      items: [
        {
          location: { name: 'HAB_301', temp: '20°' },
          risk: 'Ulcera por presión',
          patient: { first: 'Cesar', last: 'Diaz' },
          eps: 'Salud Total',
          medicine: 3,
          cons_medicine: true,
          profile: true,
          action: 1,
        },
        {
          location: { name: 'HAB_301', temp: '30°' },
          risk: 'Alergia',
          patient: { first: 'Cesar', last: 'Diaz' },
          eps: 'sura',
          medicine: 4,
          cons_medicine: true,
          profile: true,
          action: 1,
        },
        {
          location: { name: 'HAB_301', temp: '23°' },
          risk: 'Ulcera por presión',
          patient: { first: 'Cesar', last: 'Diaz' },
          eps: 'Coosalud',
          medicine: 2,
          cons_medicine: true,
          profile: true,
          action: 3,
        },
        {
          location: { name: 'HAB_301', temp: '21°' },
          risk: 'Caída',
          patient: { first: 'Cesar', last: 'Diaz' },
          eps: 'Salud Total',
          medicine: 5,
          cons_medicine: true,
          profile: true,
          action: 2,
        },
        {
          location: { name: 'HAB_301', temp: '32°' },
          risk: 'Ulcera por presión',
          patient: { first: 'Cesar', last: 'Diaz' },
          eps: 'Salud Total',
          medicine: 3,
          cons_medicine: true,
          profile: true,
          action: 3,
        },
      ],
      earningsChart: {
        series: [53, 16, 31],
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['App', 'Service', 'Product'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'App',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  setup() {
    const roleOptions = [
      { label: 'Cédula de ciudadania', value: 'CC' },
      { label: 'Cédula de extrangería', value: 'CE' },
      { label: 'Tarjeta de indentidad', value: 'TI' },
      { label: 'Pasaporte', value: 'PAP' },
    ]
    const genderOptions = [
      { label: 'Masculino', value: 'M' },
      { label: 'Femenino', value: 'F' },
      { label: 'No especificar', value: 'NONE' },
    ]
    return {
      roleOptions,
      genderOptions,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.container-estadisticas {
  display: flex;
  justify-content: space-between;
  .container-for-indicative{
    display: flex;
    flex-direction: column;
    margin: 2rem 0rem;
    background-color: #F3F2F7;
    padding: 2rem 2rem;
    .header-container {
      display: flex;
      flex: 1;
      justify-content: space-around;
    }
    .body-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .container-indicatives {
        display: flex;
        .container-indicative {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 1rem 2rem;
          .icon-indicative {
            width: 3rem;
            height: 3rem;
            margin-bottom: .5rem;
          }
          .tam-indicative {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.container-medicine {
    display: flex;
    align-items: center;
    .btn-edit-medicine {
        margin-right: 1rem;
    }
}

.container-qr {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-patient {
  width: 100%;
  margin: auto;
}
.state {
  width: 1rem;
  height: 1rem;
  display: flex;
  margin: auto;
  border-radius: 50%;
  &.done {
    background-color: #2FCB74;
  }
  &.pendient {
    background-color: #FFAC5D;
  }
  &.cancel {
    background-color: #EC6364;
  }
}
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
